import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatDialogModule } from '@angular/material/dialog';
import { NewRequestsRoutingModule } from './new-requests-routing.modules';

import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
 
import { PointRequestsComponent } from './point-requests/point-requests.component';
import { MatButtonModule } from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatRadioModule} from '@angular/material/radio';
import {MatMenuModule} from '@angular/material/menu';
import { AddComponent } from './add/add.component';
import { ShowComponent } from './show/show.component';
import { NgxEchartsModule } from 'ngx-echarts';
@NgModule({
  declarations:[ PointRequestsComponent ,AddComponent ,ShowComponent], 
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,  
    NewRequestsRoutingModule, 
    SharedModule,
    MatDialogModule,
    MatSelectModule,
    MatFormFieldModule,
    NgMultiSelectDropDownModule,
    MatPaginatorModule,
    MatTableModule,
    MatInputModule,
    MatSlideToggleModule,
    MatIconModule,
    MatButtonModule , 
    MatDividerModule, 
    MatRadioModule,
    MatMenuModule,
    MatExpansionModule,
    NgxEchartsModule
   ],
   exports: [ PointRequestsComponent]
})
export class NewRequestsModule { }
