import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from 'src/app/services/global.service';
@Component({
  selector: 'app-update-passwords',
  templateUrl: './update-passwords.component.html',
  styleUrls: ['./update-passwords.component.scss']
})
export class UpdatePasswordsComponent implements OnInit {
  form!:FormGroup;
  submitted:boolean=false ; 

  deleted_eye:boolean=false; confirm_deleted_eye:boolean=false ;old_deleted_eye:boolean=false ;
  old_pass_type:any='password' ;  pass_type:any='password' ; confirm_pass_type:any='password';
    
  data:any=[]
  constructor(titleService:Title , private formbuilder:FormBuilder, private toastr:ToastrService , private service:GlobalService){
    titleService.setTitle('تعديل الرقم السري')  
  }
   
  ngOnInit() {
    this.data=[
      {label:'الصفحة الرئيسية', path:'/home'},
      {label:'الملف الشخصي', path:'/profile/edit'} ,
      {label:'تعديل الرقم السري', active:true} 
    ]
    this.form = this.formbuilder.group({
      old_password:[null,Validators.required],
      password:[null,Validators.required],
      confirm_password:[null,Validators.required],
   })

   this.service.setRefresh(this.data)
  }

  showOldpassword(type:any) {
    this.old_deleted_eye=! this.old_deleted_eye ; 
    if(type=='password') {
     this.old_pass_type='text'
    }
    else {
     this.old_pass_type='password'
    }
  }

  showpassword(type:any) {
    this.deleted_eye=! this.deleted_eye ; 
    if(type=='password') {
     this.pass_type='text'
    }
    else {
     this.pass_type='password'
    }
  }

  showconfirmpassword(type:any) {
    this.confirm_deleted_eye=! this.confirm_deleted_eye ; 
    if(type=='password') {
    this.confirm_pass_type='text'
    }
    else {
    this.confirm_pass_type='password'
    }
  }
 
  get f():any {return this.form.controls}

  onSubmit(){
   this.submitted=true;
   if(this.form.invalid){ return}
   let send_form ={
    password:this.form.value.password , 
    old_password:this.form.value.old_password,
    'confirm-password':this.form.value.confirm_password
   }
  this.service.updatePassword(send_form).subscribe((res:any)=>{
    //  console.log("success" ,res)
      this.toastr.success(res?.message ,'success') 
    
    },(err:any)=>{
      this.toastr.error(err?.error?.message,'error');
    })
  }
}
