import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/components/auth/authentication.service';
declare var $,jQuery:any

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  public a =[]
  public b = []
  user:any;
    
  status=false
  public myList=[] ;
  constructor( private authenticationService:AuthenticationService,private service:GlobalService, private spinner:NgxSpinnerService,private router:Router ,private route: ActivatedRoute ) {
    this.authenticationService.currentUser.subscribe(currentUserSubject => this.user = currentUserSubject)
  
    this.myList =[
      { name:"bank_accounts", id:"48", role:"التحويلات البنكية", add:"" , add_route:'', list:"قائمة التحويلات البنكية" , list_route:'/bank/list' , toggle_icon:'false'  , icon:'assets/images/dollar-sign.svg'}, 
      { name:"new_requests", id:"49", role:"طلبات النقاط", add:"" ,add_route:'', list:"قائمة طلبات النقاط" , list_route:'/points/list' ,toggle_icon:'false' , icon:'assets/images/briefcase.svg'}, 
      { name:"settings", id:"32", role:"الإعدادات العامة", add:"" , add_route:'', list:"الإعدادات العامة" , list_route:'/settings/edit' ,toggle_icon:'false' , icon:'assets/images/settings.svg'},  
    ];
     
  }
 
  ngOnInit(): void {
      // left sidebar and vertical menu
      if ($('#pageWrapper').hasClass('compact-wrapper')) {
        jQuery('.sidebar-title').append('<div class="according-menu"><i class="fa fa-angle-right"></i></div>');
        jQuery('.sidebar-title').click(function () {
            jQuery('.sidebar-title').removeClass('active').find('div').replaceWith('<div class="according-menu"><i class="fa fa-angle-right"></i></div>');
            jQuery('.sidebar-submenu, .menu-content').slideUp('normal');
            jQuery('.menu-content').slideUp('normal');
            if (jQuery(this).next().is(':hidden') == true) {
                jQuery(this).addClass('active');
                jQuery(this).find('div').replaceWith('<div class="according-menu"><i class="fa fa-angle-down"></i></div>');
                jQuery(this).next().slideDown('normal');
            } else {
                jQuery(this).find('div').replaceWith('<div class="according-menu"><i class="fa fa-angle-right"></i></div>');
            }
        });
        jQuery('.sidebar-submenu, .menu-content').hide();
        jQuery('.submenu-title').append('<div class="according-menu"><i class="fa fa-angle-right"></i></div>');
        jQuery('.submenu-title').click(function () {
            jQuery('.submenu-title').removeClass('active').find('div').replaceWith('<div class="according-menu"><i class="fa fa-angle-right"></i></div>');
            jQuery('.submenu-content').slideUp('normal');
            if (jQuery(this).next().is(':hidden') == true) {
                jQuery(this).addClass('active');
                jQuery(this).find('div').replaceWith('<div class="according-menu"><i class="fa fa-angle-down"></i></div>');
                jQuery(this).next().slideDown('normal');
            } else {
                jQuery(this).find('div').replaceWith('<div class="according-menu"><i class="fa fa-angle-right"></i></div>');
            }
        });
        jQuery('.submenu-content').hide();
      } else if ($('#pageWrapper').hasClass('horizontal-wrapper')) {
        var contentwidth = jQuery(window).width();
        if ((contentwidth) < '992') {
            $('#pageWrapper').removeClass('horizontal-wrapper').addClass('compact-wrapper');
            $('.page-body-wrapper').removeClass('horizontal-menu').addClass('sidebar-icon');
            jQuery('.submenu-title').append('<div class="according-menu"><i class="fa fa-angle-right"></i></div>');
            jQuery('.submenu-title').click(function () {
                jQuery('.submenu-title').removeClass('active');
                jQuery('.submenu-title').find('div').replaceWith('<div class="according-menu"><i class="fa fa-angle-right"></i></div>');
                jQuery('.submenu-content').slideUp('normal');
                if (jQuery(this).next().is(':hidden') == true) {
                    jQuery(this).addClass('active');
                    jQuery(this).find('div').replaceWith('<div class="according-menu"><i class="fa fa-angle-down"></i></div>');
                    jQuery(this).next().slideDown('normal');
                } else {
                    jQuery(this).find('div').replaceWith('<div class="according-menu"><i class="fa fa-angle-right"></i></div>');
                }
            });
            jQuery('.submenu-content').hide();
  
            jQuery('.sidebar-title').append('<div class="according-menu"><i class="fa fa-angle-right"></i></div>');
            jQuery('.sidebar-title').click(function () {
                jQuery('.sidebar-title').removeClass('active');
                jQuery('.sidebar-title').find('div').replaceWith('<div class="according-menu"><i class="fa fa-angle-right"></i></div>');
                jQuery('.sidebar-submenu, .menu-content').slideUp('normal');
                if (jQuery(this).next().is(':hidden') == true) {
                    jQuery(this).addClass('active');
                    jQuery(this).find('div').replaceWith('<div class="according-menu"><i class="fa fa-angle-down"></i></div>');
                    jQuery(this).next().slideDown('normal');
                } else {
                    jQuery(this).find('div').replaceWith('<div class="according-menu"><i class="fa fa-angle-right"></i></div>');
                }
            });
            jQuery('.sidebar-submenu, .menu-content').hide();
        }
      }
  }
  
  onClickBTN(){
    this.status=!this.status
    var targetDiv = document.getElementsByClassName("sidebar-wrapper")[0] as HTMLElement  ;
    var targetDiv2 = document.getElementsByClassName("sidebar-main")[0] as HTMLElement  ;
    var targetBody = document.getElementsByClassName("page-body")[0] as HTMLElement  ;
    var targetBTN = document.getElementById("toggle-sidebar")  as HTMLElement ;
    var targetHeader = document.getElementsByClassName("page-header")[0] as HTMLElement  ;
    var targetFooter = document.getElementsByClassName("footer")[0] as HTMLElement  ;
    var targetFooter = document.getElementsByClassName("footer")[0] as HTMLElement  ;
  
     
    if(this.status==true){
      targetDiv.style.width='280px';
      targetHeader.style.marginRight='280px';
      targetBody.style.marginRight='280px';
      targetFooter.style.marginRight='280px';
      targetBTN.style.left='30px';
      targetDiv2.style.display='block';
      targetHeader.style.width='calc(100%-280px)';
     }
    else {
      targetDiv.style.width='35px';
      targetBTN.style.left='5px';
      targetDiv2.style.display='none';
      targetBody.style.marginRight='0px';
      targetFooter.style.marginRight='0px';
      targetHeader.style.marginRight='0px';
      targetHeader.style.width='100%';
     }
  }
}
