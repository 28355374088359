import { Component,OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {
  form!:FormGroup;
  submitted:boolean=false ; 
  data:any=[]
  constructor(titleService:Title , private formbuilder:FormBuilder,private toastr:ToastrService , private service:GlobalService){
    titleService.setTitle('تعديل بيانات الملف الشخصي')  
  }
    
  ngOnInit() {
    this.data=[
      {label:'الصفحة الرئيسية', path:'/home'},
      {label:'تعديل بيانات الملف الشخصي', active:true} 
    ]

    this.form = this.formbuilder.group({
      name:[null,Validators.required],
      phone:[null,Validators.required],
      email:[null,Validators.required],
    })
    this.service.setRefresh(this.data)
    this.getAdminData();
   }

   image_exist:boolean =false ;
   uploded_img:any=[]
   public imagePath :File[]= [];
   images : string[] = [];
   file_names:any=[]
 
   onFileChange(event:any) {
     this.image_exist =true ;
      this.uploded_img=event.target.files[0]
     if (event.target.files && event.target.files[0]) {
          var filesAmount = event.target.files.length;
          for (let i = 0; i < filesAmount; i++) {
               var reader = new FileReader();
               this.imagePath.push(...event.target.files)
               reader.onload = (event:any) => {
                 this.images.push(event.target.result); 
               }
            reader.readAsDataURL(event.target.files[i]);
          }
      }
      console.log("form", this.uploded_img)
   }


  profile_details:any
  getAdminData(){
    this.service.getProfile().subscribe((res:any)=>{
      this.profile_details=res?.data
      console.log("getProfile", res?.data)
      this.form.patchValue({
        name:this.profile_details?.name || null,
        phone: this.profile_details?.phone || null,
        email : this.profile_details?.email || null
      })
    })
    
  }
 
  get f():any {return this.form.controls}

  onSubmit(){
   this.submitted=true;
   if(this.form.invalid){ return}

   let send_form = {logo: this.uploded_img, ...this.form.value}
    this.service.updateProfile(send_form).subscribe((res:any)=>{
    //  console.log("success" ,res)
      this.toastr.success(res?.message ,'success') 
   },(err:any)=>{
      this.toastr.error(err?.error?.message,'error');
    })
  }
}
