import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as saveAs from 'file-saver';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
 
  private refresh:BehaviorSubject<any> = new BehaviorSubject<any>('');
  public getRefresh(): Observable<any> { return this.refresh.asObservable()}  //recieve
  public setRefresh(value: any):void { this.refresh.next(value) } //send
 
  constructor(private http:HttpClient , private router:Router) { }

 
  ////////////////download files/////////////////
  downloadFiles(id,type){
    this.http.get(`${environment.endpoint}/admin/points/export/${id}?type=${type}`, { responseType: 'blob' }).subscribe(
      (response:any) => {
          var blob = new Blob([response], { type: 'text/plain' });
          var fileURL = window.URL.createObjectURL(blob);
            saveAs(blob, `example-file.${type}`);
       },
      (e:any) => {
       console.log(e)
       }
    );
  }
  ////////////////Notification///////////////////
 
  notificationList(page:any){
    return this.http.get(`${environment.endpoint}/notifications?page=${page}`) 
  }

  changeNotificationStatus(id:any){
    return this.http.get(`${environment.endpoint}/notifications/read/${id}`) 
  }
  /////////////////Statistics////////////////////

  homeStatistics(){
    return this.http.get(`${environment.endpoint}/home`) 
  }
 
 
  /////////////////// User Point-Requests///////////////////////

  allPointsRequests(page,url:any){
    return this.http.get(`${environment.endpoint}/points?page=${page}${url}`) //here
  }

  deletepointfile(id:any){
    return this.http.post(`${environment.endpoint}/points/delete/${id}`,null) //here
  }

  // changePointStatus(id:any) {
  //   return this.http.post(`${environment.endpoint}/admin/points/change-status/${id}`,null)
  // }

  // changePointsApproval(id:any) {
  //   return this.http.get(`${environment.endpoint}/admin/points/approve/${id}`)
  // }
  addPointFile(file:any){ 
    const formData:any = new FormData()
    formData.append('file' ,file)
    return this.http.post(`${environment.endpoint}/add-points`,formData) 
  }

  changePointsArchive(id:any) {
    return this.http.post(`${environment.endpoint}/points/archive/${id}`,null) //here
  }
  ///////////////////// User Bank-Requests//////////////////////

  allMovementRequests(page,url:any){
    return this.http.get(`${environment.endpoint}/movements?page=${page}${url}`) //here
  }

  deleteMovementfile(id:any){
    return this.http.post(`${environment.endpoint}/movements/delete/${id}`,null) //here
  }

  addMovement(form:any){
    const formData:any = new FormData()
    formData.append('file' ,form.file)
    formData.append('amount' ,form.amount)
    formData.append('bank_account_id' ,form.bank_account_id)
    return this.http.post(`${environment.endpoint}/add-movement`,formData) 
  }
  // changeMovementStatus(id:any,form:any) {
  //   return this.http.post(`${environment.endpoint}/admin/movements/status/${id}`,form)
  // }
 
  changeMovementArchive(id:any) {
    return this.http.post(`${environment.endpoint}/movements/archive/${id}`,null)  //here
  }
 
  ///////////////////Bank Accounts//////////////////////

  bankAccountsList(){
    return this.http.get(`${environment.endpoint}/bank-accounts`)  //here
  }
 
  /////////////////////Setting/////////////////////////
  
  getSettings(){
    return this.http.get(`${environment.endpoint}/settings`) //here
  }

  /////////////////////User Profile/////////////////////////
  updateProfile(form:any) {
    const formData:any = new FormData()
    formData.append('name' ,form.name)
    formData.append('phone' ,form.phone)
    formData.append('email' ,form.email)
    formData.append('logo' ,form.logo)
    return this.http.post(`${environment.endpoint}/update-profile`,formData) 
  }

  updatePassword(form:any) {
    return this.http.post(`${environment.endpoint}/update-password`,form) 
  }

  getProfile(){
    return this.http.get(`${environment.endpoint}/myaccount`) 
  }

  //////////////Create Filter Link/////////////////////
  changeUrlParam(key:any, value:any){
  
    key = encodeURIComponent(key);
    value = encodeURIComponent(value);

    var kvp = document.location.search.substr(1).split('&');
    let i=0;

    for(; i<kvp.length; i++){
        if (kvp[i].startsWith(key + '=')) {
            let pair = kvp[i].split('=');
            pair[1] = value;
            kvp[i] = pair.join('=');
            break;
        }
    }

    if(i >= kvp.length){
        kvp[kvp.length] = [key,value].join('=');
    }

    // can return this or...
    let params = kvp.join('&');
    //console.log("params",params)

    return params ; 
  }

   
  ///////////////////New Requests //////////////////////
  // allPointsList(form:any){
  //   return this.http.get(`${environment.endpoint}/points?is_user_archive=${form.is_user_archive} `) 
  // }

  // allMovementList(form:any){
  //   return this.http.get(`${environment.endpoint}/movements?is_archive=${form.is_user_archive}`) 
  // }

}