import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';  
import { BankTransferComponent } from './bank-transfer/bank-transfer.component';
 
 
const routes: Routes = [
  { path:'list', component: BankTransferComponent} ,
 ];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BankAccountsRoutingModule { }
 