import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-place-holder-table',
  templateUrl: './place-holder-table.component.html',
  styleUrls: ['./place-holder-table.component.scss']
})
export class PlaceHolderTableComponent {
  @Input('header') header:any;
}
