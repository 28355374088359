import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from 'src/app/services/global.service';
 

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddComponent implements OnInit {
  show_loading:boolean=false ; 
  files:File[]=[];
 
  constructor( @Inject(MAT_DIALOG_DATA) public data:any ,public dialogRef: MatDialogRef<AddComponent> , private service:GlobalService , private toast:ToastrService ,private spinner: NgxSpinnerService) { }

  ngOnInit() {
  }
 
  uploadFile(event:any){
    this.files[0]= event.target.files[0]  
    //console.log(this.files[0] , this.files)  
    this.show_loading=true ;
    setTimeout(()=>{
      this.show_loading=false ; 
    },700)
 }

//  downloadExcelTemp() {}


  onSubmit() {
    this.spinner.show();
    this.service.addPointFile( this.files[0]).subscribe((res:any)=>{
      if(res.status==true){
        this.spinner.hide();
        this.toast.success(res?.message ,'success') 
        this.dialogRef.close()
      }
      else {
        this.spinner.hide()
        this.toast.error(res?.message,'error');
      }
    },(err:any)=>{
      this.spinner.hide()
      this.toast.error(err?.error?.message,'error');
   })
  }
}
