import { Component, Input, OnInit,ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from 'src/app/services/global.service';
import { Title } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { environment } from 'src/environments/environment';
import { AddComponent } from '../add/add.component';
 

@Component({
  selector: 'app-bank-transfer',
  templateUrl: './bank-transfer.component.html',
  styleUrls: ['./bank-transfer.component.scss']
})
export class BankTransferComponent implements OnInit { // , AfterViewInit  
  displayedColumns: string[] = ['id' ,'created_at', 'amount', 'account_num','status','reason', 'approved_at'   ,'logoPath' ,'settings'] ;
  dataSource:any  
  @Input('user_statistics_id') user_statistics_id:any;
  ///////////////////////Filter////////////////////////
 
  requests:any=[]
  data:any;
  showrequests:boolean=false;
  @ViewChild('paginator') paginator!: MatPaginator;
  @Input() pageIndex: number;
  total:any=100;
  environment=environment.files_end_point
  constructor(public titleService:Title, public dialog:MatDialog,private service:GlobalService , private toast:ToastrService , private spinner:NgxSpinnerService) {}  
 
  ngOnInit()  {
    this.pageIndex=1
   
      this.titleService.setTitle('قائمة التحويلات البنكية') 
      this.data=[
        {label:'الصفحة الرئيسية', path:'/home'},
        {label:'قائمة التحويلات البنكية', active:true} 
      ]
      this.service.setRefresh(this.data)
      let link= this.service.changeUrlParam('is_user_archive',0)
      this.allRequests(this.pageIndex,link);
      // this.allRequests(this.pageIndex ,'');
     
  }

  onPaginateChange(event){
    this.pageIndex=event.pageIndex+1
    // console.log("pageIndex",this.pageIndex)
    // this.allRequests(this.pageIndex,'')  
    
    let link= this.service.changeUrlParam('is_user_archive',0)
    this.allRequests(this.pageIndex,link);
  } 
 
  deletePoint(point_id:any){
    this.service.deleteMovementfile(point_id).subscribe((res:any)=>{
       if(res.status==true){
         this.toast.success(res?.message ,'success') 
         let link= this.service.changeUrlParam('is_user_archive',0)
         this.allRequests(this.pageIndex,link);
        //  this.allRequests(this.pageIndex ,'');
       }
       else {
         this.toast.error(res?.message,'error');
       }
    },(err:any)=>{
      this.toast.error(err?.error?.message,'error');
    })
  }

  allRequests(page:any,link:any){
    this.showrequests=true ;
    this.spinner.show();
    this.service.allMovementRequests(page,link).subscribe((res:any)=>{
      this.showrequests=false ;
      this.requests=res.data['data']
      this.total=res.data.total
      this.dataSource= new MatTableDataSource(this.requests);
      this.spinner.hide();
      //console.log("requests",this.requests)
    },(err:any)=>{
      this.spinner.hide()
    })
  }

  getAllArchiveMovements(){
   // alert('getAllArchiveMovements')
    this.showrequests=true ;
    this.spinner.show();
    let link= this.service.changeUrlParam('is_user_archive',1)
 
    this.service.allMovementRequests(1,link).subscribe((res:any)=>{
      this.showrequests=false ;
      this.requests=res.data['data']
      this.total=res.data.total
      this.dataSource= new MatTableDataSource(this.requests);
      this.spinner.hide();
      // console.log("requests",this.requests)
    })
  }
 
  archiveMovement(id) {
    this.service.changeMovementArchive(id).subscribe((res:any)=>{
    //  console.log("success" ,res)
       if(res.status==true){
         this.toast.success(res?.message ,'success') 
         let link= this.service.changeUrlParam('is_user_archive',0)
         this.allRequests(this.pageIndex,link);
          // this.allRequests(this.pageIndex ,'');
       }
       else {
         this.toast.error(res?.message,'error');
       }
       
       },(err:any)=>{
         this.toast.error(err?.error?.message,'error');
    })
  }
 
  statusChange(event){
    //console.log("event",event)
    if(event.value=='archive') {
      this.paginator.firstPage()
      this.getAllArchiveMovements()
    }else {
      this.paginator.firstPage()
    //  this.allRequests(this.pageIndex ,'');
    let link= this.service.changeUrlParam('is_user_archive',0)
    this.allRequests(this.pageIndex,link);
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  addTransfer(){
    const dialogRef = this.dialog.open(AddComponent, {
      width: '800px',
      data: {data:'', title:'Refuse Request'},
    }); 
    dialogRef.afterClosed().subscribe((result:any) => {
      let link= this.service.changeUrlParam('is_user_archive',0)
      this.allRequests(this.pageIndex,link);
    });
  }

}

