import { Component , OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
 
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from 'src/app/services/global.service';
@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.scss']
})
export class SettingComponent  implements OnInit {
  form!:FormGroup;
  submitted:boolean=false ; 
  settings:any;
  data:any=[]
  constructor(titleService:Title ,private formbuilder:FormBuilder, private spinner: NgxSpinnerService ,
   private toastr:ToastrService , private service:GlobalService){
    titleService.setTitle('بيانات التواصل و الدعم الفني')  
   }
    
  ngOnInit() {
    this.data=[
      {label:'الصفحة الرئيسية', path:'/home'},
      {label:'بيانات التواصل و الدعم الفني', active:true} 
    ]

    this.form = this.formbuilder.group({
      email:this.formbuilder.array([]), 
      phone:this.formbuilder.array([]), 
      banks: this.formbuilder.array([]), 
   })
    
    this.service.setRefresh(this.data)
    this.getAdminData();
    this.getBankAccounts();
  }

  getAdminData(){
    this.spinner.show()
    this.service.getSettings().subscribe((res:any)=>{
      this.spinner.hide();
      this.settings=res.data;
      console.log("settings",this.settings)

      let emails:any=this.settings.email.split(',')
      for(let e=0 ; e<emails.length ; e++) {
        this.email.push(this.formbuilder.control(emails[e]))
      }
     
      let phone:any=this.settings.phone.split(',')
      for(let e=0 ; e<phone.length ; e++) {
        this.phone.push(this.formbuilder.control(phone[e]))
      }
    },(err:any)=>{
      this.spinner.hide()
    })
  }

  getBankAccounts(){
    this.service.bankAccountsList().subscribe((res:any)=>{
      for (let i=0 ; i<res?.data.length ; i++){
        this.addBank()
        const control2 = this.banks['controls'][i]
        control2.controls.bank_name.value =res?.data[i]?.bank_name
        control2.controls.bank_number.value =res?.data[i]?.bank_number
       }
    })
  
  }

  get banks():any {
   return this.form.get("banks") as FormArray
  }
 
  newBank() {
    return this.formbuilder.group({
      bank_name:['',Validators.required],
      bank_number:['',Validators.required],
     });
  }

  addBank() {
   this.banks.push(this.newBank())
  }
 
  get email():any {
    return this.form.get("email") as FormArray
  }
 
  addEmail() {
    this.email.push(this.formbuilder.control(null,Validators.required))
  }
   
   
  get phone():any {
    return this.form.get("phone") as FormArray
  }

  addPhone() {
    this.phone.push(this.formbuilder.control(null,Validators.required))
  }
   
  get f():any {return this.form.controls}
  
}
