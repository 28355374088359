import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from 'src/app/shared/shared.module';
import { ProfileRoutingModule } from './profile-routing.modules';
import { EditComponent } from './edit/edit.component';
import { UpdatePasswordsComponent } from './update-passwords/update-passwords.component';
 
// import { InfiniteScrollModule } from 'ngx-infinite-scroll';
 // import { TranslateModule } from '@ngx-translate/core'; 
@NgModule({
  declarations: [EditComponent ,UpdatePasswordsComponent], 
  imports: [
    CommonModule,
    ProfileRoutingModule,
    FormsModule,
    ReactiveFormsModule,   
    MatIconModule,
    SharedModule,
  ]
})
export class ProfileModule { }
