import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/components/auth/authentication.service';
import { GlobalService } from 'src/app/services/global.service';
import { environment } from 'src/environments/environment';
declare var $,jQuery:any

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  user:any ;
  href:any ;
  public dark = false
  admin:any
  notifications:any=[]
  unreadnotifications:any=[]
  constructor(private authentication:AuthenticationService , private service:GlobalService ,private router:Router) { 
    this.authentication.currentUser.subscribe(currentUserSubject => this.user = currentUserSubject)
    this.admin=this.user.data;


    service.notificationList(1).subscribe((res:any)=>{
      this.notifications=res['data'].data
      this.notifications.forEach((note:any) => {
        if(note.read!=1){
         this.unreadnotifications.push(note)
        }
      });
     // console.log("notifications",this.notifications)
     })
  }

  ngOnInit(): void {
    $(".left-header .level-menu .header-level-menu").css('display', 'none');
    $('.left-header .level-menu .nav-link').on('click', function (e) {
        $(this).toggleClass("active");
        $(this).parent().children('.header-level-menu').toggleClass("d-block").slideToggle();
    });
    $(document).click(function (e) {
      $('.translate_wrapper, .more_lang').removeClass('active');
    });
    $('.translate_wrapper .current_lang').click(function (e) {
      e.stopPropagation();
      $(this).parent().toggleClass('active');

      setTimeout(function () {
          $('.more_lang').toggleClass('active');
      }, 5);
    });
  }

  darkMode(){
    $('body').toggleClass('dark-only')
  }

  logOut(){
    localStorage.removeItem(`${environment.currentUserKey}`);
     location.reload()
     this.router.navigate([''])
  }

  changeNotificationToRead(notification_id:any){
    this.service.changeNotificationStatus(notification_id).subscribe((res:any)=>{
    //console.log(err.message)
    },
    (err:any)=>{
    //console.log(err.error)
   })
  }
}
