import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from '../authentication.service';
import { NgxSpinnerService } from "ngx-spinner";
import Swal from 'sweetalert2'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public submitted = false;
  public form:FormGroup;
  public togglePassword: boolean = false;

  constructor(private router: Router,private formbuilder:FormBuilder,private service:AuthenticationService,private spinner: NgxSpinnerService) { 
    // if (this.service.currentUserValue) { this.router.navigate(['/']) }
  }

  ngOnInit(): void {
    this.form = this.formbuilder.group({
      email:['',Validators.required], //'ejlal@gmail.com'
      password:['',Validators.required]  //'12345678'
    })
  }
  get f() {return this.form.controls}
  submit(){
    this.submitted = true;
    if (this.form.invalid) { 
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });return 
    }
    this.spinner.show()
    this.service.login(this.form.value).subscribe((response:any)=>{
    this.spinner.hide()
    //  console.log(response)
    //  console.log("login",response)
      if(response.status==true){
        this.spinner.hide()
        Swal.fire(
          `نجاح تسجيل الدخول`,
          `مرحباً بعودتك, يا أدمن`,
          `success`
          )
          this.router.navigate(["/home"])
      }
    else {
      this.spinner.hide()
     
      if(response?.message) {
        Swal.fire( `فشل تسجيل الدخول`, ` ${response?.message}`, `error`)
      }
      else {
        Swal.fire( `فشل تسجيل الدخول`, ` ${response?.errors[0]}`, `error`) 
      }
    }
        },
        
       (err:any)=>{
       this.spinner.hide()
        Swal.fire(
          `فشل تسجيل الدخول`,
          `تأكد من صحة الإيميل أو الرقم السري `,
          `error`
          )
       } 
        
        )

  }
}
