import { Component, Input, OnInit,ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from 'src/app/services/global.service';
import { Title } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator'; 
import { AddComponent } from '../add/add.component';

@Component({
  selector: 'app-point-requests',
  templateUrl: './point-requests.component.html',
  styleUrls: ['./point-requests.component.scss']
}) 
export class PointRequestsComponent implements OnInit { // ,AfterViewInit  
 
  displayedColumns: string[] = ['id','numberOfFile', 'benifit_num','points_num'  ,'approved_at' ,'status', 'reason','created_at','execute'   ,'settings'] ;
  dataSource:any  
  requests:any=[]
  data:any;
  showrequests:boolean=false;
  @ViewChild('paginator') paginator!: MatPaginator;
  @Input() pageIndex: number;
  total:any=100;
 
  constructor(public titleService:Title, public dialog:MatDialog,public service:GlobalService , private toast:ToastrService , private spinner:NgxSpinnerService) {
     
  }  
 
  ngOnInit()  {
    this.pageIndex=1
    this.titleService.setTitle('قائمة طلبات النقاط') 
    this.data=[
      {label:'الصفحة الرئيسية', path:'/home'},
      {label:'قائمة طلبات النقاط', active:true} 
    ]
    this.service.setRefresh(this.data)
    let link= this.service.changeUrlParam('is_user_archive',0)
    this.allRequests(this.pageIndex,link);
   
  }

  onPaginateChange(event){
    this.pageIndex=event.pageIndex+1
    let link= this.service.changeUrlParam('is_user_archive',0)
    this.allRequests(this.pageIndex,link);
    // this.allRequests(this.pageIndex ,'');
  } 
 
  allRequests(page:any,link:any){
    this.showrequests=true ;
    this.spinner.show();
    this.service.allPointsRequests(page,link).subscribe((res:any)=>{
      this.showrequests=false ;
      this.requests=res.data['data']
      this.total=res.data.total
      this.dataSource= new MatTableDataSource(this.requests);
      this.spinner.hide();
    //  console.clear()
    // console.log("requests",this.requests)
    },(err:any)=>{
      this.spinner.hide()
    })
  }
   
  deletePoint(point_id:any){
    this.service.deletepointfile(point_id).subscribe((res:any)=>{
       if(res.status==true){
         this.toast.success(res?.message ,'success') 
         let link= this.service.changeUrlParam('is_user_archive',0)
         this.allRequests(this.pageIndex,link);
        //  this.allRequests(this.pageIndex ,'');
       }
       else {
         this.toast.error(res?.message,'error');
       }
    },(err:any)=>{
      this.toast.error(err?.error?.message,'error');
    })
  }

  archivePoint(id:any){ // archive point row  
    this.service.changePointsArchive(id).subscribe((res:any)=>{
     // console.log("success" ,res)
       if(res.status==true){
         this.toast.success(res?.message ,'success') 
         let link= this.service.changeUrlParam('is_user_archive',0)
         this.allRequests(this.pageIndex,link);
        // this.allRequests(this.pageIndex ,'');
       }
       else {
         this.toast.error(res?.message,'error');
       }
       
       },(err:any)=>{
         this.toast.error(err?.error?.message,'error');
    })
  }

  statusChange(event){ // all archive
    // console.log("event",event)
    if(event.value=='archive') {
      this.paginator.firstPage()
      this.getAllArchivePoints()
    }else {
      this.paginator.firstPage()
      let link= this.service.changeUrlParam('is_user_archive',0)
      this.allRequests(this.pageIndex,link);
      // this.allRequests(this.pageIndex ,'');
    }
  }
  
  getAllArchivePoints() { // all archive
    let link= this.service.changeUrlParam('is_user_archive',1)
    this.showrequests=true ;
     this.spinner.show();
     this.service.allPointsRequests(1,link).subscribe((res:any)=>{
       this.showrequests=false ;
       this.requests=res.data['data']
       this.total=res.data.total
       this.dataSource= new MatTableDataSource(this.requests);
       this.spinner.hide();
     })
  }
 
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
 
  addPoints(){
    const dialogRef = this.dialog.open(AddComponent, {
      width: '800px',
      data: {data:'', title:'Refuse Request'},
    }); 
    dialogRef.afterClosed().subscribe((result:any) => {
      let link= this.service.changeUrlParam('is_user_archive',0)
      this.allRequests(this.pageIndex,link);
    });
  }

}
