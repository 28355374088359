import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigationEnd, Router } from '@angular/router';
import { ConnectionService } from 'ng-connection-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService } from './components/auth/authentication.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  isConnected = true;  
  user:any=null;
  
  constructor(private authenticationService:AuthenticationService , private spinner:NgxSpinnerService,private _snackBar: MatSnackBar,private connectionService: ConnectionService,private router: Router) {  
   // this.spinner.show();\\  
    this.authenticationService.currentUser.subscribe(currentUserSubject => this.user = currentUserSubject)
  
    this.connectionService.monitor().subscribe(isConnected => {  
     // this.spinner.hide();
      this.isConnected = isConnected;  
      if (!this.isConnected) 
        this.openSnackBar('لا يوجد اتصال بالإنترنت','موافق')
        else
        this.openSnackBar('تم استعادة الاتصال بالانترنت','موافق')
    })  

  }  

  ngOnInit(){
    // this.router.navigate(['/dashboard'])
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 5000,
    });
  }

}