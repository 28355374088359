import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';  
// import { PointRequestsComponent } from './point-requests/point-requests.component';
import { AddComponent } from './add/add.component';
import { ShowComponent } from './show/show.component';
 
const routes: Routes = [
    { path:'list', component: ShowComponent} ,
    { path:'add', component: AddComponent} ,
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NewRequestsRoutingModule { }
