import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './sidebar/sidebar.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { JumbotronComponent } from './jumbotron/jumbotron.component';
 
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatSelectModule} from '@angular/material/select';
 
import { ToastrModule } from 'ngx-toastr';
 
import { RouterModule } from '@angular/router';
import { MatDialogModule} from '@angular/material/dialog';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { MatDatepickerModule} from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatIconModule } from '@angular/material/icon'
import { HttpClientModule } from '@angular/common/http';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { PlaceHolderTableComponent } from './place-holder-table/place-holder-table.component';
import { DateMomentPipe } from '../pipes/date-moment/date-moment.pipe';
 
import {MatMenuModule} from '@angular/material/menu';

@NgModule({
  declarations: [SidebarComponent, NavbarComponent, FooterComponent, JumbotronComponent , BreadcrumbComponent ,PlaceHolderTableComponent , DateMomentPipe],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ToastrModule.forRoot({
      timeOut: 10000, 
      positionClass: 'toast-bottom-right', 
      preventDuplicates: true,
      progressBar:true
    }),
  
    RouterModule,
    MatSelectModule,
    MatInputModule,
    MatDialogModule,
    MatFormFieldModule,
    MatNativeDateModule, 
    MatDatepickerModule,
    MatButtonModule,
    MatSnackBarModule,
    MatIconModule,
    NgMultiSelectDropDownModule.forRoot(),

    MatMenuModule

  ],
  exports: [
    SidebarComponent, 
    NavbarComponent, 
    FooterComponent,
    JumbotronComponent,
    BreadcrumbComponent ,
    PlaceHolderTableComponent ,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    ToastrModule,
    RouterModule,
    DateMomentPipe
  ],  
  providers:[
  ],
})
export class SharedModule { }
