import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';  
import { EditComponent } from './edit/edit.component';
import { UpdatePasswordsComponent } from './update-passwords/update-passwords.component';
 
 
 
const routes: Routes = [
  { path:'edit', component: EditComponent } ,
  { path:'update-passwords', component: UpdatePasswordsComponent } ,   
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProfileRoutingModule { }
 