import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from 'src/app/services/global.service';
import { environment } from 'src/environments/environment';
import * as echarts from 'echarts';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
 
@Component({
  selector: 'app-show',
  templateUrl: './show.component.html',
  styleUrls: ['./show.component.scss']
})
export class ShowComponent implements OnInit {
  pointChartOptions: any = {
    
  };
  movementChartOptions: any = {
    
  };

  form!:FormGroup;
  toggle:boolean=true;
  data:any=[]
  panelOpenState = false;
  statistics:any;
  statistics_cards:any= 
  [  
    {id:0 , title:'عدد المستفيدين الكلي ' , icon:'assets/images/user-plus.svg' ,  hexcode:'#4831d4',  value:' ' },
    {id:1 , title:'عدد المستفيدين آخر 30 يوم ' , icon:'assets/images/user-plus.svg' , hexcode:'#CFB0ED', value:'' },
    {id:2 , title:'عدد المستفيدين سنويا', icon:'assets/images/user-plus.svg', hexcode:'#8AD8CA',  value:'' , path:'/new-requests/points'},
    
    {id:3 , title:'نوع المستفيدين' , icon:'assets/images/users.svg' , hexcode:'#8AD8CA',  value:'' },
   
    {id:4 , title:'عدد التحويلات البنكية المرسلة ' , icon:'assets/images/calendar.svg', hexcode:'#9AC3FF',  value:'' , path:'/new-requests/points'},
    {id:5 , title:'عدد التحويلات البنكية المعتمدة' , icon:'assets/images/calendar.svg', hexcode:'#a3479c',  value:'' , path:'/new-requests/points'},
    
    {id:6 , title:'آخر رصيد تم إضافته' , icon:'assets/images/dollar-sign.svg',  hexcode:'#ea2087',  value:'' },
    {id:7 , title:'المتبقي من الرصيد الكلي' , icon:'assets/images/dollar-sign.svg',  hexcode:'#F8B271',  value:'' },
    {id:8 , title:'مجموع الرصيد المالي المضاف', icon:'assets/images/dollar-sign.svg'  ,  hexcode:'#8AD8CA',  value:'' , path:'/new-requests/points'},
    // assets/images/briefcase.svg
    
    {id:9 , title:'إجمالي النقاط المصروفة' , icon:'assets/images/clipboard.svg', hexcode:'#9ac3ff',  value:'' },
    {id:10 , title:'عدد النقاط آخر شهر' , icon:'assets/images/clipboard.svg' , hexcode:'#ea2087', value:'' , path:'/partner/list'},
    {id:11 , title:'عدد النقاط  سنويا ', icon:'assets/images/clipboard.svg'  ,  hexcode:'#CFB0ED',  value:'' , path:'/new-requests/points'},
    {id:12, title:'عدد النقاط المتبقي ' , icon:'assets/images/clipboard.svg', hexcode:'#F8B271',  value:'' },
    {id:13, title:'إجمالي النقاط التي تساوي الرصيد المضاف' , icon:'assets/images/clipboard.svg', hexcode:'#4831d4',  value:'' },
 
  ]
  submitted:boolean=false;
  // user_id:any = this.route.snapshot.paramMap.get('id');
  environment=environment.files_end_point;
  details:any
  constructor(private formbuilder:FormBuilder, titleService:Title, public service:GlobalService , public route:ActivatedRoute , private toast:ToastrService ,  public dialog:MatDialog ,private spinner: NgxSpinnerService) { 
  
 
    titleService.setTitle('الإحصائيات') 
    this.data=[
      {label:'الصفحة الرئيسية', path:'/home'},
      // {label:'قائمة النقاط', path:'/partner/list'},
      {label:'الإحصائيات', active:true} 
    ]
    this.service.setRefresh(this.data)

    this.form = this.formbuilder.group({
      name:[null,Validators.required],
      phone:[null,Validators.required],
      email:[null,Validators.required],
      // point_per_one:[null,Validators.required],
      password:[null,Validators.required]
    })
    
    this.myAccountDetails()
    this.getStatistics()
  }

  ngOnInit(): void {
    
  }
 
  myAccountDetails(){
    this.service.getProfile().subscribe((res:any)=>{
      this.details=res?.data
      // console.log("myAccountDetails" , res.data)
      this.form.patchValue({
        name: res?.data?.name, 
        phone: res?.data?.phone, 
        email: res?.data?.email, 
      })
    })
  }
 
  getStatistics(){
    this.service.homeStatistics().subscribe((res:any)=>{
        this.statistics=res.data
       // console.log("statistics", this.statistics)
        this.statistics_cards[0].value=this.statistics?.total_count || '-';
        this.statistics_cards[1].value=this.statistics?.last_month || '-';
        this.statistics_cards[2].value=this.statistics?.last_year;

        this.statistics_cards[3].value={male:this.statistics?.males , female: this.statistics?.females };

       
        this.statistics_cards[4].value=this.statistics?.all_movement; 
        this.statistics_cards[5].value=this.statistics?.approved_movement;

        this.statistics_cards[6].value=this.statistics?.last_movement?.amount || '-';
        this.statistics_cards[7].value= this.statistics?.user?.balance|| '-';
        this.statistics_cards[8].value=this.statistics?.edited_balance;

       
       
        
        this.statistics_cards[9].value=this.statistics?.total_points;
        this.statistics_cards[10].value=this.statistics?.last_month_total_points;
        this.statistics_cards[11].value=this.statistics?.last_year_total_points;
        this.statistics_cards[12].value=this.statistics?.remain_points;
        this.statistics_cards[13].value=this.statistics?.edited_balance_pointe;
    
      if(this.statistics?.monthly_points.length!=0){
        this.pointChartOptions= this.createChart(this.statistics?.monthly_points , 'bar')
      }
      
      if(this.statistics?.monthly_movements.length!=0){
        this.movementChartOptions= this.createChart(this.statistics?.monthly_movements , 'bar')
      }
    
    })
    
  }

  createChart(arrOfObj:any , chartType:any){
    let chartData:any=[]
    let chart:any=[]
    chartData.length=12;
    arrOfObj.forEach((elem:any)=>{
      let index =elem.monthyear.split('-')
      chartData[Number(index[0])-1]=elem.data
      
    })
    chartData= Array.from(chartData, item => item || 0)
    // console.log("result",chartData)
    chartData.forEach((data:any) => {
    chart.push({value:data , itemStyle: { color: new echarts.graphic.LinearGradient(
                            0, 0, 0, 1,
                            [
                                {offset: 0, color: '#ea208726'},
                                {offset: 0.5, color:'#4831d4'},
                                {offset: 1, color: '#4831d4'}
                            ]
                          )}, 
                        })
    });
    let chartOption = {
      xAxis: {
        type: 'category',
        data: [ 'يناير', 'فبراير', 'مارس', 'أبريل', 'مايو', 'يونيو', 'يوليو' ,'أغسطس' ,'سبتمبر' ,'أكتوبر' ,'نوفمبر','ديسمبر'],
     },
      yAxis: {
        type: 'value',
      },
      series: [
        {
          barWidth: '40%',
          data: chart, 
          type:chartType,  
        },
      ],
    };

   return chartOption;
  }

  image_exist:boolean =false ;
  uploded_img:any=[]
  public imagePath :File[]= [];
  images : string[] = [];
  file_names:any=[]

  onFileChange(event:any) {
    this.image_exist =true ;
     this.uploded_img=event.target.files[0]
    if (event.target.files && event.target.files[0]) {
         var filesAmount = event.target.files.length;
         for (let i = 0; i < filesAmount; i++) {
              var reader = new FileReader();
              this.imagePath.push(...event.target.files)
              reader.onload = (event:any) => {
                this.images.push(event.target.result); 
              }
           reader.readAsDataURL(event.target.files[i]);
         }
     }
    //  console.log("form", this.uploded_img)
  }

  newShade(hex:any){
    let c:any;
    if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
        c= hex.substring(1).split('');
        if(c.length== 3){
            c= [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c= '0x'+c.join('');
        return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',0.09)';
    }
     throw new Error('Bad Hex');
  }

  get f():any {return this.form.controls}

  onSubmit(){
   delete this.form.value.email
   this.submitted=true;
   let sent_form ={
      logo: this.uploded_img,
      ...this.form.value
    }
    // console.log("sent_form",sent_form)
    this.spinner.show()
    this.service.updateProfile(sent_form).subscribe((res:any)=>{
      this.spinner.hide()
      this.toast.success(res?.message ,'success') 
      this.myAccountDetails();
    },(err:any)=>{
      this.spinner.hide();
      this.toast.error(err?.error?.message,'error');
    })
  }
}
